<template>
    <div>
        <el-radio-group v-model="status" size="mini" @change="orderChange">
            <el-radio-button v-for="r in radioList" :key="r.status" :label="r.status">{{ r.statusStr }}（{{ r.count }}）</el-radio-button>
        </el-radio-group>

        <jy-query ref="form" :model="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="调度单号" prop="scheduleId">
                <el-input v-model="form.scheduleId"></el-input>
            </jy-query-item>
            <jy-query-item label="运输类型" prop="transportT">
                <el-select v-model="form.transportT">
                    <el-option label="整车" value="0"></el-option>
                    <el-option label="散货" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="分拣中心名称" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="司机" prop="driverNa">
                <el-input v-model="form.driverNa"></el-input>
            </jy-query-item>
            <jy-query-item label="调整发车日期" prop="planT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.planT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="创建日期" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="form.createT"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck" v-if="btnexist('wastesDispatchOrderList')">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')" v-if="btnexist('wastesDispatchOrderReset')">重置</el-button>
                <el-row>
                    <el-button size="small" type="primary" v-on:click="add" v-if="btnexist('wastesDispatchOrderInsert')">新增</el-button>
                    <export-excel
                        v-if="btnexist('wastesDispatchOrderExport') && false"
                        baseUrl="/iwastes-admin"
                        url="/schedule/export"
                        :params="form"
                        fileName="调度单"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="调度单号" prop="scheduleId" min-width="160" show-overflow-tooltip>
                </jy-table-column>
                <jy-table-column label="运输类型" prop="transportT" min-width="100">
                    <template v-slot="scope">{{ scope.row.transportT === "1" ? "散货" : "整车" }}</template>
                </jy-table-column>
                <jy-table-column label="状态" prop="status" min-width="100">
                    <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template>
                </jy-table-column>
                <jy-table-column label="分拣中心名称" prop="unitId" min-width="160" show-overflow-tooltip>
                    <template v-slot="scope">{{ formatUnit(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="车牌号" show-overflow-tooltip min-width="140">
                    <template v-slot="scope">{{ scope.row.vehicleInfo && scope.row.vehicleInfo.plateNum }}</template>
                </jy-table-column>
                <jy-table-column label="司机" show-overflow-tooltip min-width="120">
                    <template v-slot="scope">{{ scope.row.driverList | formatDrivers }}</template>
                </jy-table-column>
                <jy-table-column label="关联委托订单数"  min-width="100">
                    <template v-slot="scope">
                        <el-button type="text" size="small" @click="navToWOrder(scope.row.scheduleId)">{{ scope.row.orderCount }}</el-button>
                    </template>
                </jy-table-column>
                <jy-table-column label="调度发车时间" prop="pTime" min-width="160"></jy-table-column>
                <jy-table-column v-if="showRTime" label="实际发车时间" prop="rTime" min-width="160"></jy-table-column>
                <jy-table-column v-if="showFTime" label="完成运输时间" prop="fTime" min-width="160"></jy-table-column>
                <jy-table-column label="创建人" prop="cUserNa"></jy-table-column>
                <jy-table-column label="创建时间" prop="createT" min-width="160"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="200"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";

    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        mixins: [btnMixins],
        created() {
            this.setOperateList();
        },
        mounted() {
            if (this.$route.params.scheduleId) {
                this.form.scheduleId = this.$route.params.scheduleId;
                this.status = "";
                console.log('d')
            }
            this.getList();
        },
        activated() {
            if (this.$route.params.scheduleId) {
                this.form.scheduleId = this.$route.params.scheduleId;
                this.status = "";
                this.getList();
            }
        },
        filters: {
            formatDrivers(list) {
                if (list && list.length) {
                    return list.map(v => v.cName).join(",");
                }
                return "-";
            }
        },
        methods: {
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                let url = "/schedule/pageQuery";
                this.loading = true;
                this.$http
                    .post(
                        url,
                        {
                            ...this.form,
                            status: this.status,
                            pTimeStart: this.$util.addTime(this.form.planT && this.form.planT[0], true),
                            pTimeEnd: this.$util.addTime(this.form.planT && this.form.planT[1]),
                            createTStart: this.$util.addTime(this.form.createT && this.form.createT[0], true),
                            createTEnd: this.$util.addTime(this.form.createT && this.form.createT[1]),
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize,
                            planT: undefined,
                            createT: undefined
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list;
                        this.total = detail.total;
                        this.dataList.forEach(one => {
                            if (one.vehicleInfo && one.vehicleInfo.plateNum) {
                                one.plateNum = one.vehicleInfo.plateNum;
                            }
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            add() {
                this.$emit("switch");
            },
            orderChange() {
                this.getList();
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleEdit(row) {
                this.$emit("switch", row);
            },
            handleView(row) {
                this.$emit("switch", {
                    ...row,
                    view: true
                });
            },
            handleDelete(row) {
                this.$confirm("是否删除该调度单?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/schedule/delete";
                        this.$http.post(url, row.scheduleId).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            handleCancel(row) {
                this.$confirm("是否取消该调度单?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        let url = "/schedule/cancel";
                        this.$http.post(url, row.scheduleId).then(res => {
                            this.$message({
                                type: "success",
                                message: res.msg
                            });
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            setOperateList() {
                let l = [
                    {
                        name: "查看",
                        icon: "el-icon-edit-outline",
                        fun: this.handleView,
                        isShow: () => {
                            return this.btnexist("wastesDispatchOrderView");
                        }
                    },
                    {
                        name: "修改",
                        icon: "el-icon-edit-outline",
                        fun: this.handleEdit,
                        isShow: row => {
                            return this.btnexist("wastesDispatchOrderUpdate") && (row.status == "0" || row.status == "1");
                        }
                    },
                    {
                        name: "删除",
                        icon: "el-icon-delete",
                        fun: this.handleDelete,
                        isShow: row => {
                            return this.btnexist("wastesDispatchOrderDelete") && (row.status == "0" || row.status == "2");
                        }
                    },
                    {
                        name: "取消调度单",
                        icon: "el-icon-delete",
                        fun: this.handleCancel,
                        isShow: row => {
                            return this.btnexist("wastesDispatchOrderCancel") && row.status == "1";
                        }
                    }
                ];
                this.operateList = l;
            },
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatStatus(key) {
                const s = this.radioList.find(v => v.status === key);
                return s.statusStr;
            },
            navToWOrder(scheduleId) {
                this.$router.push({
                    name: "order",
                    params: {
                        scheduleId
                    }
                });
            }
        },
        computed: {
            unitList() {
                return this.$parent.unitList;
            },
            radioList() {
                return this.$parent.countList || [];
            },
            showRTime() {
                return ["", "1", "3"].includes(this.status);
            },
            showFTime() {
                return ["", "3"].includes(this.status);
            }
        },
        data() {
            return {
                loading: false,
                form: {
                    scheduleId: "",
                    transportT: "",
                    unitId: "",
                    plateNum: "",
                    driverNa: "",
                    planT: [],
                    createT: []
                },
                dataList: [],
                total: 0,

                pageIndex: 1,
                pageSize: 10,
                status: "0",
                operateList: [],

                btnMenuId: "wastesDispatchOrder"
            };
        },
        components: {
            ExportExcel
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 15px;
    }
</style>
