<template>
    <el-dialog :visible.sync="visible" title="选择委托单" width="70%">
        <jy-query ref="form" :model="model" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="委托单号" prop="wOrderId">
                <el-input v-model="model.wOrderId"></el-input>
            </jy-query-item>
            <jy-query-item label="产废企业" prop="unitId">
                <el-select v-model="model.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别" prop="wasteTId">
                <el-select v-model="model.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="抛/重物" prop="nature">
                <el-select v-model="model.nature">
                    <el-option label="抛物" value="1"></el-option>
                    <el-option label="重物" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="包装方式" prop="pack">
                <el-select v-model="model.pack">
                    <el-option v-for="p in packWayList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属行业" prop="industry">
                <el-select v-model="model.industry">
                    <el-option v-for="p in industryList" :key="p.iValue" :value="p.iValue" :label="p.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业类型" prop="wUnitType">
                <el-select v-model="model.wUnitType">
                    <el-option v-for="t in orgTypeList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业产废类别" prop="wasteT">
                <el-select v-model="model.wasteT">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="企业产废废物" prop="wasteNa">
                <el-input v-model="model.wasteNa"></el-input>
            </jy-query-item>
            <jy-query-item label="发起人" prop="cUserNa">
                <el-input v-model="model.cUserNa"></el-input>
            </jy-query-item>
            <jy-query-item label="发起日期" prop="createT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="model.createT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="期望日期" prop="expireT">
                <el-date-picker type="daterange" value-format="yyyy-MM-dd" v-model="model.expireT"></el-date-picker>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="model.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList" ref="table">
                <jy-table-column type="selection" width="55"></jy-table-column>
                <jy-table-column label="序号" type="index"></jy-table-column>
                <jy-table-column label="委托单号" prop="wOrderId"></jy-table-column>
                <jy-table-column label="产废企业" prop="unitNa"></jy-table-column>
                <jy-table-column label="固废类别" prop="wasteTNa"></jy-table-column>
                <jy-table-column label="拟固废清运量">
                    <template v-slot="scope">{{ formatTWeight(scope.row) }}</template>
                </jy-table-column>
                <jy-table-column label="期望到达时间">
                    <template v-slot="scope">{{ scope.row.expireT + scope.row.amOrPm == "1" ? "下午" : "上午" }}</template>
                </jy-table-column>
                <jy-table-column label="产废设施地址" prop="addr"></jy-table-column>
                <jy-table-column label="抛/重物">
                    <template v-slot="scope">{{ scope.row.nature === "1" ? "抛物" : "重物" }}</template>
                </jy-table-column>
                <jy-table-column label="包装方式" prop="pack"></jy-table-column>
                <jy-table-column label="企业固废照片">
                    <template v-slot="scope">
                        <img class="avatar" :src="scope.row.picUrl" v-if="scope.row.picUrl"/>
                        <span v-else>-</span>
                    </template>
                </jy-table-column>
                <jy-table-column label="状态">
                    <template v-slot="scope">{{ formatStatus(scope.row.status) }}</template>
                </jy-table-column>
                <jy-table-column label="所属行业">
                    <template v-slot="scope">{{ scope.row.unitInfo | formatIndustry }}</template>
                </jy-table-column>
                <jy-table-column label="企业类型">
                    <template v-slot="scope">{{ scope.row.unitInfo | formatWUnitType }}</template>
                </jy-table-column>
                <jy-table-column label="企业产废类别">
                    <template v-slot="scope">{{ formatWasteT(scope.row.unitInfo) }}</template>
                </jy-table-column>
                <jy-table-column label="企业产废废物">
                    <template v-slot="scope">{{ scope.row.unitInfo | formatWasteNa }}</template>
                </jy-table-column>
                <jy-table-column label="发起人" prop="cUserNa">
                    <template v-slot="scope">{{ scope.row | formatUserName }}</template>
                </jy-table-column>
                <jy-table-column label="发起时间" prop="createT"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa"></jy-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <div class="handle_buttons">
            <el-button type="primary" size="small" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <institutions-tree :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </el-dialog>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        components: { institutionsTree },
        filters: {
            formatUserName(row){
                if (row.cUserId) {
                    return row.cUserNa
                }else{
                    return row.unitNa
                }
            },
            formatIndustry(val) {
                return val ? val.industry : "-";
            },
            formatWUnitType(val) {
                return val ? val.wUnitType : "-";
            },
            formatWasteNa(val) {
                return val ? val.wasteNa : "-";
            }
        },
        methods: {
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.model.orgNa = "";
                this.oncheck();
            },
            formatTWeight(row) {
                const w = this.wasteTypes.find(v => v.wasteTId === row.wasteTId);
                if (w) {
                    return row.weight + w.unit;
                } else {
                    return row.weight;
                }
            },
            formatWasteT(unitInfo) {
                if (unitInfo) {
                    const w = this.wasteTypes.find(v => v.wasteTId === unitInfo.wasteT);
                    return w ? w.cName : "-";
                }
                return "-";
            },
            formatStatus(key) {
                const s = this.countList.find(v => v.status === key);
                return s.statusStr;
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            addInstitutions(data) {
                this.model.orgId = data.orgId;
                this.model.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            makeSure() {
                const rows = this.$refs.table.selection;
                if (!rows.length) {
                    this.$message.warning({
                        message: "请选择委托订单"
                    });
                    return;
                }
                this.$emit("select", rows);
                this.visible = false;
            },
            getList() {
                let url = "/wasteOrder/pageQuery";
                this.loading = true;
                this.$http
                    .post(
                        url,
                        {
                            ...this.model,
                            status: "0",
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize,
                            notBind: "1",
                            expireBeginT: this.$util.addTime(this.model.expireT && this.model.expireT[0], true),
                            expireEndT: this.$util.addTime(this.model.expireT && this.model.expireT[1]),
                            createBeginTStr: this.$util.addTime(this.model.createT && this.model.createT[0], true),
                            createEndTStr: this.$util.addTime(this.model.createT && this.model.createT[1]),
                            createT: undefined,
                            expireT: undefined
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list;
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            init() {
                this.visible = true;
                this.getList();
            },
            initDicts() {
                this.$http.post("/wasteOrder/countList").then(res => {
                    this.countList = res.detail;
                });
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictpackway").then(res => {
                    this.packWayList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictindustry").then(res => {
                    this.industryList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictorgtype").then(res => {
                    this.orgTypeList = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "0"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
            }
        },
        created() {
            this.initDicts();
        },
        data() {
            return {
                loading: false,
                visible: false,
                model: {
                    wOrderId: "",
                    wasteTId: "",
                    nature: "",
                    pack: "",
                    unitId: "",
                    industry: "",
                    wasteT: "",
                    wasteNa: "",
                    cUserNa: "",
                    orgNa: "",
                    orgId: "",
                    createT: [],
                    expireT: []
                },
                dataList: [],
                total: 0,
                pageSize: 10,
                pageIndex: 1,

                orgTypeList: [],
                wasteTypes: [],
                industryList: [],
                packWayList: [],
                countList: [],
                unitList: []
            };
        }
    };
</script>
<style scoped>
    .avatar {
        width: 100%;
        height: 100%;
    }
    .handle_buttons {
        text-align: center;
    }
</style>
