<template>
    <div>
        <el-page-header @back="backMain" :content="'调度单 > ' + title"></el-page-header>
        <el-form ref="dOrder" :model="model" :rules="rules" class="detail_form" size="small" label-position="right" label-width="130px">
            <div class="form_item" v-if="!view">
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="运输类型" prop="transportT">
                            <el-radio-group v-model="model.transportT">
                                <el-radio-button label="0">整车</el-radio-button>
                                <el-radio-button label="1">散货</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="分拣中心名称" prop="unitId">
                            <el-select v-if="!isTransport" v-model="model.unitId" @change="changeUnit">
                                <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                            </el-select>
                            <span v-else>{{ formatUnit(model.unitId) }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="分拣中心地址">{{ model.addr }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="调度发车时间" prop="pTime">
                            <el-date-picker v-if="!isTransport" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" v-model="model.pTime"></el-date-picker>
                            <span v-else>{{ model.pTime }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item" v-else>
                <div class="head_title">基本信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="调度单号">{{ model.scheduleId }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态">{{ formatStatus(model.status) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="运输类型">{{ model.transportT == "0" ? "整车" : "散货" }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="分拣中心名称">{{ formatUnit(model.unitId) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="分拣中心地址">{{ model.addr }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="调度发车时间">{{ model.pTime }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="实际发车时间">{{ model.rTime }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="完成运输时间">{{ model.fTime }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">{{ model.cUserNa }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">{{ model.createT }}</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">车辆信息</div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="车牌号" prop="vehicleId" v-if="!view">
                            <el-input @focus="addVehicle" size="small" style="width: 180px" :value="vehicleInfo.plateNum"></el-input>
                            <el-button size="small" style="margin-left: 10px" @click="getVehicle">一键调度</el-button>
                        </el-form-item>
                        <el-form-item label="车牌号" v-else>{{ vehicleInfo.plateNum }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆类型">{{ formatVType(vehicleInfo.vType) }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆品牌">{{ vehicleInfo.vendor }}</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="车辆型号">{{ vehicleInfo.model }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="燃料种类">{{ vehicleInfo.energyT }}</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="总质量">{{ vehicleInfo.wTotal }}kg</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="整备质量">{{ vehicleInfo.wCurb }}kg</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="核定载质量">{{ vehicleInfo.wLoad }}kg</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="外型尺寸">{{ vehicleInfo.sizeT }}（mm）</el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="货厢尺寸">{{ vehicleInfo.sizeBox }}（mm）</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="车辆容积">{{ vehicleInfo.volume }}方</el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="准乘人数">{{ vehicleInfo.pasNu }}人</el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="form_item">
                <div class="head_title">司机信息</div>
                <jy-table :data="drivers">
                    <jy-table-column label="姓名" prop="cName"></jy-table-column>
                    <jy-table-column label="性别">
                        <template v-slot="scope">{{ scope.row.gender === "1" ? "男" : "女" }}</template>
                    </jy-table-column>
                    <jy-table-column label="联系方式" prop="phone"></jy-table-column>
                    <jy-operate v-if="!view" v-bind:list="driverOperateList" width="100"></jy-operate>
                </jy-table>
                <div class="table_bottom_button" v-if="!view && !isTransport">
                    <el-button type="text" icon="el-icon-plus" @click="addDriver">选择司机</el-button>
                    <el-button @click="getDriver" size="small">一键调度</el-button>
                </div>
            </div>
            <div class="form_item">
                <div class="head_title">固废清运明细</div>
                <el-form class="rubbish-list" v-if="view && status==3" label-width="120px">
                    <el-row>
                        <el-col :span="8" class="text-center">清运总重量</el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重">{{ model.grossW }}</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重">{{ model.tareW }}</el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" class="text-center key-word">{{ formatLabels(wOrders) }}kg</el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅时间">{{ model.grossWT }}</el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅时间">{{ model.tareWT }}</el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8"><div class="placeholder"></div></el-col>
                        <el-col :span="8">
                            <el-form-item label="毛重过磅照片"><img class="avatar" :src="model.grossWUrl" /></el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="皮重过磅照片"><img class="avatar" :src="model.tareWUrl" /></el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <jy-table :data="wOrders" v-if="!view">
                    <jy-table-column label="委托单号" prop="wOrderId"></jy-table-column>
                    <jy-table-column label="产废企业">
                        <template v-slot="scope">{{ scope.row.unitInfo ? scope.row.unitInfo.cName : "" }}</template>
                    </jy-table-column>
                    <jy-table-column label="固废类别">
                        <template v-slot="scope">{{ scope.row.wasteTNa }}</template>
                    </jy-table-column>
                    <jy-table-column label="拟固废清运量">
                        <template v-slot="scope">{{ formatTWeight(scope.row) }}</template>
                    </jy-table-column>
                    <jy-table-column label="期望到达时间" prop="expireT"></jy-table-column>
                    <jy-table-column label="产废设施地址" prop="addr"></jy-table-column>
                    <jy-table-column label="抛/重物">
                        <template v-slot="scope">{{ scope.row.nature === "1" ? "抛物" : "重物" }}</template>
                    </jy-table-column>
                    <jy-table-column label="包装方式" prop="pack"></jy-table-column>
                    <jy-table-column label="发起人" prop="cUserNa"></jy-table-column>
                    <jy-table-column label="发起时间" prop="createT"></jy-table-column>
                    <jy-operate v-bind:list="ordersOperateList" width="100"></jy-operate>
                </jy-table>
                <el-table :data="wOrders" v-else>
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="left" inline class="wOrders-table-expand">
                                <el-row>
                                    <el-col :span="8">
                                        <el-form-item label="实际装运时间">
                                            <span>{{ props.row.grossWT }}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="装运照片">
                                            <img class="avatar" :src="props.row.grossWUrl" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="8">
                                        <el-form-item label="实际净重">
                                            <span>{{ props.row.rWeight }}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column label="委托单号" prop="wOrderId"></el-table-column>
                    <el-table-column label="产废企业">
                        <template v-slot="scope">{{ scope.row.unitInfo ? scope.row.unitInfo.cName : "" }}</template>
                    </el-table-column>
                    <el-table-column label="固废类别">
                        <template v-slot="scope">{{ scope.row.wasteTNa }}</template>
                    </el-table-column>
                    <el-table-column label="拟固废清运量">
                        <template v-slot="scope">{{ formatTWeight(scope.row) }}</template>
                    </el-table-column>
                    <el-table-column label="期望到达时间" prop="expireT"></el-table-column>
                    <el-table-column label="产废设施地址" prop="addr"></el-table-column>
                    <el-table-column label="抛/重物">
                        <template v-slot="scope">{{ scope.row.nature === "1" ? "抛物" : "重物" }}</template>
                    </el-table-column>
                    <el-table-column label="包装方式" prop="pack"></el-table-column>
                    <el-table-column label="发起人" prop="cUserNa"></el-table-column>
                    <el-table-column label="发起时间" prop="createT"></el-table-column>
                </el-table>
                <el-button v-if="!view" class="table_bottom_button" type="text" icon="el-icon-plus" @click="addWOrder">选择委托订单</el-button>
            </div>
            <div class="form_item">
                <div class="head_title">运输到到时间</div>
                <jy-table :data="transports">
                    <jy-table-column label="清运地址" prop="addr"></jy-table-column>
                    <jy-table-column :label="timeLabel" prop="arriveT">
                        <template v-slot="scope" v-if="!view">
                            <el-date-picker value-format="yyyy-MM-dd" type="date" v-model="scope.row.arriveT"></el-date-picker>
                            <el-select v-model="scope.row.amOrPm" class="expire_time_range">
                                <el-option label="上午" value="0"></el-option>
                                <el-option label="下午" value="1"></el-option>
                            </el-select>
                        </template>
                        <template v-else v-slot="scope">
                            <span>{{ scope.row.arriveT }}</span>
                            <span>{{ scope.row.amOrPm == "0" ? "上午" : "下午" }}</span>
                        </template>
                    </jy-table-column>
                </jy-table>
            </div>
        </el-form>
        <div class="form_handle" v-if="!view">
            <el-button type="primary" size="small" @click="makeSure">确定</el-button>
            <el-button size="small" @click="backMain">取消</el-button>
        </div>

        <select-vehicle ref="vehicle" @select="selectVehicle"></select-vehicle>
        <select-driver ref="driver" @select="selectDriver"></select-driver>
        <select-dispatch-order ref="wOrder" @select="selectWOrder"></select-dispatch-order>
    </div>
</template>

<script>
    import SelectVehicle from "@/components/pages/admin/selectVehicle/SelectVehicle.vue";
    import SelectDriver from "@/components/pages/admin/selectDriver/SelectDriver.vue";
    import SelectDispatchOrder from "@/components/pages/admin/selectDispatchOrder/SelectDispatchOrder.vue";
    export default {
        methods: {
            formatTWeight(row) {
                const w = this.wasteTypes.find(v => v.wasteTId === row.wasteTId);
                if (w) {
                    return row.weight + w.unit;
                } else {
                    return row.weight;
                }
            },
            formatNumber(v1, v2) {
                if (isNaN(v1) || isNaN(v2)) {
                    return "-";
                }
                return v1 - v2;
            },
            formatVType(type) {
                const t = this.vTypeList.find(v => v.rValue == type);
                if (t) {
                    return t.iValue;
                }
                return "-";
            },
            formatRUnit(key) {
                const u = this.rUnitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            formatWaste(val) {
                const w = this.wasteTypes.find(v => v.wasteTId === val);
                return w ? w.cName : "-";
            },
            formatLabels(list) {
                let number = 0,
                    strSet = new Set();
                if (list && list.length) {
                    list.forEach(v => {
                        number += v.weight;
                        strSet.add(this.formatWaste(v.wasteTId));
                    });
                    return [...strSet].join(",") + number;
                } else {
                    return "-";
                }
            },
            formatStatus(key) {
                const s = this.radioList.find(v => v.status === key);
                return s ? s.statusStr : "";
            },
            formatUnit(key) {
                const u = this.unitList.find(v => v.unitId === key);
                return u ? u.cname : "-";
            },
            backMain() {
                this.$emit("switch");
            },
            changeUnit(key) {
                const unit = this.unitList.find(v => v.unitId === key);
                this.model.addr = unit.addr;
                this.model.orgId = unit.orgId;
                this.model.mOrgId = unit.morgId;
            },
            selectVehicle(row) {
                Object.assign(this.vehicleInfo, row);
                this.model.vehicleId = row.vehicleId;
            },
            // driverCheckFun(list){
            //     let hasＷork = list.some(one=>one.hasWork=='1')
            //     if (hasＷork) {
            //         this.$message.warning("所选司机当天有任务")
            //     }
            //     return !hasＷork
            // },
            selectDriver(list) {
                list.forEach(v => {
                    const index = this.drivers.findIndex(d => d.driverId === v.driverId);
                    if (index === -1) {
                        this.drivers.push(v);
                    }
                });
            },
            selectWOrder(list) {
                if (this.model.transportT == "0") {
                    const len = list.length;
                    const one = list[len - 1];
                    this.wOrders = [one];
                } else if (this.model.transportT == "1") {
                    this.wOrders = this.wOrders.concat(list);
                }
            },
            addDriver() {
                if (this.model.pTime && this.model.unitId) {
                    let pTime = new Date(this.model.pTime).Format("yyyy-MM-dd");
                    this.$refs.driver.init({ pTime });
                } else {
                    this.$message.warning({
                        message: "请先选择分拣中心和调度发车时间"
                    });
                }
            },
            addVehicle() {
                if (this.model.unitId && this.model.pTime) {
                    let pTime = new Date(this.model.pTime).Format("yyyy-MM-dd");
                    this.$refs.vehicle.init({ pTime });
                } else {
                    this.$message.warning({
                        message: "请先选择分拣中心和调度发车日期"
                    });
                }
            },
            addWOrder() {
                this.$refs.wOrder.init();
            },
            getDriver() {
                if (this.model.pTime || this.model.orgId) {
                    this.$http
                        .post("/base/driver/randomDriverInfo", {
                            pTime: this.model.pTime.split(" ")[0],
                            orgId: this.model.orgId
                        })
                        .then(res => {
                            const { detail } = res;
                            const index = this.drivers.findIndex(d => d.driverId === detail.driverId);
                            if (index === -1) {
                                this.drivers = this.drivers.concat(detail);
                            }
                        });
                } else {
                    this.$message.warning({
                        message: "请先选择分拣中心和调度发车日期"
                    });
                }
            },
            getVehicle() {
                if (this.model.pTime || this.model.orgId) {
                    this.$http
                        .post("/base/vehicle/randomVehicleInfo", {
                            pTime: this.model.pTime.split(" ")[0],
                            orgId: this.model.orgId
                        })
                        .then(res => {
                            const { detail } = res;
                            Object.assign(this.vehicleInfo, detail);
                            this.model.vehicleId = detail.vehicleId;
                        });
                } else {
                    this.$message.warning({
                        message: "请先选择分拣中心和调度发车日期"
                    });
                }
            },
            makeSure() {
                if (!this.driverList.length) {
                    this.$message({
                        type: 'error',
                        message: '请选择运输司机'
                    });
                    return;
                }
                 if (!this.transports.length) {
                    this.$message({
                        type: 'error',
                        message: '请选择委托订单'
                    });
                    return;
                }
                let num = 0
                this.transports.forEach(one=>{
                    if (one.arriveT) {
                        num++
                    }
                })
                if (num < this.transports.length) {
                    this.$message({
                        type:'error',
                        message:'请设置预计到达时间'
                    })
                    return
                }
                this.$refs.dOrder.validate(valide => {
                    if (valide) {
                        let url = "/schedule/add";
                        if (this.model.scheduleId) {
                            url = "/schedule/update";
                        }
                        this.$http
                            .post(url, {
                                ...this.model,
                                orderList: this.transports,
                                driverList: this.driverList
                            })
                            .then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                this.$emit("switch");
                            });
                    }
                });
            },
            handleDriversRemove(row, one, index) {
                this.drivers.splice(index, 1);
            },
            handleOrdersRemove(row, one, index) {
                this.wOrders.splice(index, 1);
            },
            initDicts() {
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "0"
                    })
                    .then(res => {
                        this.rUnitList = res.detail;
                    });
                this.$http.post("/dict/getDictValuesByDictId", "dictvehicletype").then(res => {
                    this.vTypeList = res.detail;
                });
            }
        },
        created() {
            this.initDicts();
        },
        mounted() {
            if (this.$parent.option) {
                const option = this.$parent.option;
                this.$http.post("/schedule/getDetailByScheduleId", option.scheduleId).then(res => {
                    this.model = res.detail;
                    this.status = res.detail.status
                    const { driverList, orderList, detailList } = res.detail;
                    this.drivers = driverList || [];
                    orderList &&
                        orderList.forEach(v => {
                            const one = detailList.find(d => d.wOrderId === v.wOrderId);
                            if (one) {
                                v.arriveT = one.arriveT;
                                v.cUserNa = res.detail.cUserNa;
                            }
                        });
                    this.wOrders = orderList || [];
                    console.log(this.wOrders);

                    this.vehicleInfo = res.detail.vehicleInfo;
                });

                this.view = option.view;
                if (this.model.status == "1") {
                    this.isTransport = true;
                }
                if (this.view) {
                    this.title = "查看";
                } else {
                    this.title = "编辑";
                }
            }
        },
        data() {
            return {
                status:0,
                title: "新增",
                model: {
                    transportT: "0",
                    unitId: "",
                    addr: "",
                    pTime: "",
                    vehicleId: ""
                },
                rules: {
                    transportT: [{ required: true, message: "运输类型不为空", trigger: "change" }],
                    unitId: [{ required: true, message: "分拣中心不为空", trigger: "change" }],
                    pTime: [{ required: true, message: "调度发车不为空", trigger: "change" }],
                    vehicleId: [{ required: true, message: "车牌号不为空", trigger: "change" }]
                },

                vehicleInfo: {
                    plateNum: "",
                    vType: "",
                    vendor: "",
                    model: "",
                    energyT: "",
                    wTotal: "",
                    wCurb: "",
                    pasNu: "",
                    wLoad: "",
                    sizeT: "",
                    sizeBox: "",
                    volume: ""
                },

                drivers: [],
                wOrders: [],
                wasteTypes: [],
                rUnitList: [],
                vTypeList: [],

                driverOperateList: [
                    {
                        name: "移除",
                        icon: "el-icon-edit-outline",
                        fun: this.handleDriversRemove,
                        isShow: () => {
                            return true;
                        }
                    }
                ],
                ordersOperateList: [
                    {
                        name: "移除",
                        icon: "el-icon-edit-outline",
                        fun: this.handleOrdersRemove,
                        isShow: () => {
                            return true;
                        }
                    }
                ],

                isTransport: false, // 运输中

                view: false
            };
        },
        computed: {
            timeLabel(){
                return (this.status==3?'':'预计')+'到达时间'
            },
            unitList() {
                return this.$parent.unitList;
            },
            transports() {
                return this.wOrders.map(v => {
                    return {
                        scheduleId: this.model.scheduleId,
                        addr: v.addr,
                        wOrderId: v.wOrderId,
                        arriveT: v.arriveT,
                        amOrPm: v.amOrPm || "0"
                    };
                });
            },
            driverList() {
                return this.drivers.map(v => {
                    return { driverId: v.driverId, scheduleId: this.model.scheduleId };
                });
            },
            radioList() {
                return this.$parent.countList || [];
            }
        },
        components: {
            SelectVehicle,
            SelectDriver,
            SelectDispatchOrder
        }
    };
</script>
<style lang="scss" scoped>
    .avatar {
        height: 100px;
        width: 100px;
    }
    .wOrders-table-expand {
        padding: 0 10%;
    }
    .rubbish-list {
        padding: 0 15%;
        .text-center {
            text-align: center;
        }
        .key-word {
            font-size: 20px;
            font-weight: 700;
        }
        .placeholder {
            width: 1px;
            height: 1px;
        }
    }
    .detail_form {
        margin-top: 20px;
        .form_item {
            margin-bottom: 30px;
            .head_title {
                font-size: 18px;
            }
            .head_title::before {
                content: "";
                display: inline-block;
                width: 7px;
                height: 26px;
                margin-right: 5px;
                vertical-align: middle;
                background-color: #3498db;
            }

            .table_bottom_button {
                width: 100%;
                border: 1px dashed #dcdcdc;
                text-align: center;
            }
        }
    }
    .form_handle {
        text-align: center;
    }

    .expire_time_range {
        margin-left: 8px;
        width: 80px;
    }
</style>
