<template>
    <transition>
        <component :is="current" @switch="switchPage" ref="page"></component>
    </transition>
</template>

<script>
    import Detail from "./detail.vue";
    import List from "./list.vue";
    export default {
        data() {
            return {
                current: "List",

                unitList: [],
                countList: [],

                option: null
            };
        },
        async created() {
            let p1 = this.$http.post("/schedule/countList"),
                p2 = this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "3"
                });
            Promise.all([p1, p2])
                .then(res => {
                    const [r1, r2] = res;
                    this.countList = r1.detail;
                    this.unitList = r2.detail;
                })
                .then(() => {
                    this.$refs.page.getList && this.$refs.page.getList();
                });
        },
        methods: {
            switchPage(option) {
                if (this.current === "List") {
                    this.current = "Detail";
                } else {
                    this.current = "List";
                }
                this.option = option;
            }
        },
        components: {
            Detail,
            List
        }
    };
</script>
<style></style>
